
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
  getCurrentInstance ,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import ChaKanTouSu from "./TouSu.vue";
import { nextTick } from 'vue';
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigTouSu",
  setup(props, { emit }) {
    const datePickerVisible = ref(false);
    // const {proxy} = getCurrentInstance() as any
    // const instance = getCurrentInstance() as any;
    let data = reactive<any>({
        dialogFormVisible: false,
      init: false,
      url: "",
      maps: new Map(),
      data: {},
      option: {},
      title: "",
      total: 0, // 总条目数
      marketingSwitchingList: [],
      SpName:"",
      switchSpName:"",
      SpValue: '',
      switchSpValue: '',
      isSelect: 1,
      packageList: [],
      DateSelect: [],
      addForm: {
        MarketingId: "",
        SwitchingId: "",
      },
        
    });

    const selectSpName = (item: any) => { 
        console.log("data.PackageName");
        console.log(item);
        data.SpValue = parseInt(item.PackageId);
        data.SpName = item.PackageName;
        
    };

    const selectSpName2 = (item: any) => { 
        // console.log("data.PackageName2");
        // console.log(item);
        data.switchSpValue = parseInt(item.PackageId);
        data.switchSpName = item.PackageName;
        
    };
    const showMarketingSwitching = () => {
        data.dialogFormVisible = true;
    };
    const getMarketingSwitching = () => {
    
      data.marketingSwitchingList = [];
      Api.Config.GetMarketingSwitchingList().then((res: any) => {
        // console.log("res");
        // console.log(res);
        if (res.Code == 0) {  
            for(var i = 0; i < res.Data.length; i++){ 
                res.Data[i].SwitchingAt = res.Data[i].SwitchingAt.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').substring(0, 19);
                data.marketingSwitchingList.push(res.Data[i]);
                // list.push(res.Data.data[i])
            }
        } 
      });
            // console.log(data.complaintFormStatisticsList);
    };
    const addMarketingSwitching = () => { 
        data.marketingSwitchingList = [];
        let marketingSwitchingdata = {MarketingId: ''+data.SpValue , SwitchingId: ''+data.switchSpValue};
        console.log(marketingSwitchingdata);
      Api.Config.AddMarketingSwitching(marketingSwitchingdata).then((res: any) => {
        // console.log("res");
        // console.log(res);
        if (res.Code == 0) {  
            ElMessage.success("新增成功");
            data.dialogFormVisible = false;
            getMarketingSwitching();
        } 
        else{
            ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
        }
      });
    };
    const getPackageList = () => {
        data.packageList = [];
        Api.Config.GetPackageList('XJ').then((res: any) => {
            // console.log("res");
            // console.log(res);
            if (res.Code == 0) {  
                for(var i = 0; i < res.Data.length; i++){ 
                    data.packageList.push(res.Data[i]);
                }
            } 
        });
    };
    onMounted(() => {
        getPackageList()
        getMarketingSwitching();
        
        // console.log(proxy);
    });

    return {
      ...toRefs(data),
      addMarketingSwitching,
      getMarketingSwitching,
      showMarketingSwitching,
      getPackageList,
      selectSpName,
      selectSpName2,
    };
  },
});
